@import '../../../styles/colors.scss';

.keystone.organisations {
  .link {
    font-weight: bolder;
    text-decoration: underline;
    color: $curious-blue; 
    cursor: pointer;
  }
  .ordered {
    overflow-y: auto;
  }
  .financials {
    min-height: 50vh;
    .justify-end {
      display: flex;
      justify-content: flex-end;
    }
    .justify-space-between {
      display: flex;
      justify-content: space-between;
    }
  }
  .remove, .refresh {
    cursor: pointer;
  }
}