@import '../../../styles/colors.scss';

.liteEDDTemplate {
  counter-reset: section;
  font-family: 'NeuzeitGro';
  .pageHeader, .pageFooter {
    height: 80px;
  }
  .pageHeaderSpace, .pageFooterSpace {
    height: 120px;
  }

  .pageFooter {
    position: fixed;
    z-index: 1;
    bottom: 0;
    width: 100%;
    background: $white;
    .dtrmFooter {
      width: 210mm;
    }
  }

  .pageHeader {
    position: fixed;
    z-index: 1;
    top: 0mm;
    background: $white;
    width: 100%;
    .dtrmHeader {
      width: 210mm;
    }
  }

  .page {
    width: 210mm;
    padding: 0 19mm;
    page-break-after: always;
    &.pageOne {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      .buffer {
        flex-basis: 100%;
      }
      .placeholder {
        height: 40mm;
      }
      .title {
        font: 72px 'NeuzeitGro';
        color: $grayish-blue;
      }
      .body {
        font: 12px 'NeuzeitGro';
        color: $catalina-blue;
        line-height: 2;
        .imageWrapper {
          position: relative;
          left: -48px;
          display: flex;
          align-items: center;
          .dtrmEntityLogo {
            width: 48px;
            height: 48px;
          }
        }
      }
    }
    &.pageTwo {
      .contentPageTitle {
        font: 20px 'NeuzeitGro';
        color: $mango-tango;
      }
      .contentPageWrapper {
        display: flex;
        justify-content: flex-end;
      }
  
      .contentTable, .disclaimer {
        width: 120mm;
      }
      .contentTable {
        font: 12px 'NeuzeitGro';
        padding-bottom: 48px;
        .row-0, .row-2, .row-3 {
          color: $mango-tango;
          line-height: 36px;
        }
        .row-1, .row-4, .row-5 {
          color: $bahama-blue;
          line-height: 36px;
        }
        .row-1, .row-4, .row-5 {
          padding-left: 16px;
        }
      }
      .disclaimer {
        font: 8px 'NeuzeitGro';
        line-height: 12px;
        .title {
          color: $bahama-blue;
        }
        .body {
          text-align: justify;
        }
      }
    }
  
    &.pageThree {
      // min-height: 1263px;
      .basicInfo {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 4mm;
        .title {
          font: 20px 'NeuzeitGro';
          color: $mango-tango;
          line-height: 2;
          width: 210mm;
        }
        .subtitle {
          font: 14px 'NeuzeitGro';
          color: $bahama-blue;  
          line-height: 2;
          width: 210mm;
        }
        .body {
          font: 11px 'NeuzeitGro';
          line-height: 2;
          text-align: justify;
          width: 210mm;
          > p {
            line-height: 2;
          }
        }
      }
      .adverseMediaAnalysis {
        display: block;
        .prelude {
          font: 11px 'NeuzeitGro';
          line-height: 2;
        }
        .title {
          font: 20px 'NeuzeitGro';
          color: $mango-tango;
          width: 210mm;
          line-height: 2;
        }
        .table {
          display: table;
          border-collapse: collapse;
        }
        .tableBody {
          display: table-row-group;
        }
        .tableRow {
          display: table-row;
        }
        .tableCell {
          border-color: $polo-blue;
          border-style: solid;
          display: table-cell;
          vertical-align: middle;
        }
        .even {
          background: $solitude;
          -webkit-print-color-adjust: exact;
        }
        .summary {
          font: 11px 'NeuzeitGro';
          line-height: 2;
          .left {
            width: 122mm;
            padding-left: 8px;
            border-width: 0 0.5px 0.5px 0;
          }
          .right {
            width: 50mm;
            text-align: center;
            border-width: 0 0 0.5px 0;
          }
        }
        .details {
          margin-top: 10mm;
          font: 11px 'NeuzeitGro';
          line-height: 2;
          .header {
            font-weight: bold;
            .cellOne {
              border-width: 0 0px 0.5px 0;
              width: 30mm;
              padding-left: 8px;
            }
            .cellTwo {
              border-width: 0 0px 0.5px 0;
              width: 94mm;
              padding-left: 8px;
            }
            .cellThree {
              border-width: 0 0px 0.5px 0;
              width: 32mm;
              padding-left: 8px;
            }
            .cellFour {
              border-width: 0 0px 0.5px 0;
              width: 16mm;
              padding-left: 8px;
            }
          }
          .tableBody {
            .cellOne {
              border-width: 0 0.5px 0.5px 0;
              width: 30mm;
              padding-left: 8px;
              text-transform: capitalize;
            }
            .cellTwo {
              border-width: 0 0.5px 0.5px 0;
              width: 94mm;
              padding-left: 8px;
              .summarySentence {
                line-height: 1.5;
              }
            }
            .cellThree {
              border-width: 0 0.5px 0.5px 0;
              width: 32mm;
              padding-left: 8px;
            }
            .cellFour {
              border-width: 0 0 0.5px 0;
              width: 16mm;
              padding-left: 8px;
            }  
          }
        }
      }
    }
    &.pageFour {
      .blacklist {
        display: flex;
        flex-wrap: wrap;
        .title {
          font: 20px 'NeuzeitGro';
          color: $mango-tango;
          line-height: 2;
          width: 210mm;
        }
        .content {
          width: 172mm;
          .subtitle {
            font: 14px 'NeuzeitGro';
            color: $bahama-blue;  
            line-height: 2;
          }
          .body {
            font: 11px 'NeuzeitGro';
            line-height: 2;
            text-align: justify;
            p {
              line-height: 2;
            }
          }
        }
      }
    }
  }
  .fineprint {
    font: italic 11px 'NeuzeitGro';
    line-height: 2;
  }   
}

@page {
  margin: 0;
}

@media print {
  .liteEDDTemplate {
    thead {display: table-header-group;} 
    tfoot {display: table-footer-group;}
     
    button {display: none;}
    margin: 0;
  }
}