@import './styles/colors.scss';

@font-face {
  font-family: 'NeuzeitGro';
  src: local('NeuzeitGro'), url(./fonts/NeuzeitGro.ttf) format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "NeuzeitGro", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .wysiwyg-editor {
    border: $solitude 1px solid;
    border-radius: 4px;
    padding: 0 8px;
    min-height: 30vh;
  }
  .justify-space-between {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "NeuzeitGro", "Courier New",
    monospace;
}
