.express-relationship {
  .form {
    min-height: 65vh;
    .visible.menu.transition {
      max-height: 25vh;
    }
    .buttons-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }
  .ui.small.table {
    font-size: 0.75rem;
    .errorFlag {
      cursor: pointer;
    }
  }
}