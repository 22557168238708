$grayish-blue: #2E456C;
$bahama-blue: #1F497D;
$catalina-blue: #244061;
$curious-blue: #4183c4;
$maya-blue: #60bdff;
$polo-blue: #95B3D7;
$dark-blue-4: #00008B;
$solitude: #DBE5F1;
$mango-tango: #E36C0A;
$sunset-orange: #f75353;
$whisper: #ececec;
$white: #ffffff;
$gray: #444;
$classic-rose: #d5e0ee;
$whisper-red: #f0ebeb;