@import '../../../styles/colors.scss';

.keystone.people {
  .link {
    font-weight: bolder;
    text-decoration: underline;
    color: $curious-blue; 
    cursor: pointer;
  }
  .ordered {
    overflow-y: auto;
  }
  .refresh {
    cursor: pointer;
  }
}