@import '../../styles/colors.scss';

.liteedd-report {
  font-family: "NeuzeitGro";
  max-width: 80vw;
  .icon {
    cursor: pointer;
  }
  
  .entityId {
    .link {
      font-weight: bolder;
      text-decoration: underline;
      color: $curious-blue; 
      cursor: pointer;
    }
  }
  
  .sanctions-wrapper {
    display: flex;
    justify-content: space-between;
  }
  
  .search-conditions {
    > div {
      display: inline-block;
    }
    padding: 10px;
    user-select: none;
    border-radius: 3px;
    margin-bottom: 10px;
  }

  .adverse-media-results {
    .articles-container {
      .articles-header {
        display: grid;
        grid-template-columns: 5% 10% 20% 35% 15% 15%;
        font-weight: 600;
        background: $whisper;
        > div {
          word-wrap: break-word;
          padding: 5px;
        }
      }
      .full-article-row {
        padding: 10px;
        margin: 5px 10px;
        border: 1px solid $gray;
        border-radius: 3px;
        background: $white;
      }
      .article-row {
        display: grid;
        grid-template-columns: 5% 10% 20% 35% 15% 15%;
        &:nth-of-type(2n+1) {
          background: $classic-rose;
        }
    
        > div {
          word-wrap: break-word;
          padding: 5px;
        }
        .summary-sentence {
          padding-bottom: 1rem;
          font-size: 0.8rem;
        }
      }
      .article-row, .full-article-row {
        button.risk-factor-label {
          border: 0;
          font-size: 12px;
          padding: 4px 15px 2px 15px;
          cursor: pointer;
          margin-left: 10px;
          margin-bottom: 12px;
          border-radius: 2px;
          background: $whisper-red;
          border-bottom: 2px solid $maya-blue;
          &:hover {
            font-weight: 600;
          }
          outline: none;
        }
        .adverse-term {
          font-weight: 900;
          text-decoration: underline;
          color: $sunset-orange;
        }
        .entity {
          font-weight: 900;
          text-decoration: underline;
          color: $dark-blue-4;
        }
      }
    }
  }

  .add-summary-details {
    .fields {
      display: flex;
      flex-wrap: wrap;
      line-height: 2;
    }
  }
}

