@font-face {
  font-family: 'NeuzeitGro';
  src: local("NeuzeitGro"), url(/static/media/NeuzeitGro.115d1d1d.ttf) format("truetype"); }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "NeuzeitGro", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body .wysiwyg-editor {
    border: #DBE5F1 1px solid;
    border-radius: 4px;
    padding: 0 8px;
    min-height: 30vh; }
  body .justify-space-between {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding-bottom: 1rem; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "NeuzeitGro", "Courier New", monospace; }

.table-selectable {
  cursor: pointer;
}

button.btn-plain {
  box-shadow: 0 0 0 0 ;
  background: red !important;
}

.express-relationship .form {
  min-height: 65vh; }
  .express-relationship .form .visible.menu.transition {
    max-height: 25vh; }
  .express-relationship .form .buttons-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }

.express-relationship .ui.small.table {
  font-size: 0.75rem; }
  .express-relationship .ui.small.table .errorFlag {
    cursor: pointer; }

.liteedd-report {
  font-family: "NeuzeitGro";
  max-width: 80vw; }
  .liteedd-report .icon {
    cursor: pointer; }
  .liteedd-report .entityId .link {
    font-weight: bolder;
    text-decoration: underline;
    color: #4183c4;
    cursor: pointer; }
  .liteedd-report .sanctions-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .liteedd-report .search-conditions {
    padding: 10px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 3px;
    margin-bottom: 10px; }
    .liteedd-report .search-conditions > div {
      display: inline-block; }
  .liteedd-report .adverse-media-results .articles-container .articles-header {
    display: grid;
    grid-template-columns: 5% 10% 20% 35% 15% 15%;
    font-weight: 600;
    background: #ececec; }
    .liteedd-report .adverse-media-results .articles-container .articles-header > div {
      word-wrap: break-word;
      padding: 5px; }
  .liteedd-report .adverse-media-results .articles-container .full-article-row {
    padding: 10px;
    margin: 5px 10px;
    border: 1px solid #444;
    border-radius: 3px;
    background: #ffffff; }
  .liteedd-report .adverse-media-results .articles-container .article-row {
    display: grid;
    grid-template-columns: 5% 10% 20% 35% 15% 15%; }
    .liteedd-report .adverse-media-results .articles-container .article-row:nth-of-type(2n+1) {
      background: #d5e0ee; }
    .liteedd-report .adverse-media-results .articles-container .article-row > div {
      word-wrap: break-word;
      padding: 5px; }
    .liteedd-report .adverse-media-results .articles-container .article-row .summary-sentence {
      padding-bottom: 1rem;
      font-size: 0.8rem; }
  .liteedd-report .adverse-media-results .articles-container .article-row button.risk-factor-label, .liteedd-report .adverse-media-results .articles-container .full-article-row button.risk-factor-label {
    border: 0;
    font-size: 12px;
    padding: 4px 15px 2px 15px;
    cursor: pointer;
    margin-left: 10px;
    margin-bottom: 12px;
    border-radius: 2px;
    background: #f0ebeb;
    border-bottom: 2px solid #60bdff;
    outline: none; }
    .liteedd-report .adverse-media-results .articles-container .article-row button.risk-factor-label:hover, .liteedd-report .adverse-media-results .articles-container .full-article-row button.risk-factor-label:hover {
      font-weight: 600; }
  .liteedd-report .adverse-media-results .articles-container .article-row .adverse-term, .liteedd-report .adverse-media-results .articles-container .full-article-row .adverse-term {
    font-weight: 900;
    text-decoration: underline;
    color: #f75353; }
  .liteedd-report .adverse-media-results .articles-container .article-row .entity, .liteedd-report .adverse-media-results .articles-container .full-article-row .entity {
    font-weight: 900;
    text-decoration: underline;
    color: #00008B; }
  .liteedd-report .add-summary-details .fields {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    line-height: 2; }

.keystone.people .link {
  font-weight: bolder;
  text-decoration: underline;
  color: #4183c4;
  cursor: pointer; }

.keystone.people .ordered {
  overflow-y: auto; }

.keystone.people .refresh {
  cursor: pointer; }

.keystone.organisations .link {
  font-weight: bolder;
  text-decoration: underline;
  color: #4183c4;
  cursor: pointer; }

.keystone.organisations .ordered {
  overflow-y: auto; }

.keystone.organisations .financials {
  min-height: 50vh; }
  .keystone.organisations .financials .justify-end {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
  .keystone.organisations .financials .justify-space-between {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }

.keystone.organisations .remove, .keystone.organisations .refresh {
  cursor: pointer; }

.liteEDDTemplate {
  counter-reset: section;
  font-family: 'NeuzeitGro'; }
  .liteEDDTemplate .pageHeader, .liteEDDTemplate .pageFooter {
    height: 80px; }
  .liteEDDTemplate .pageHeaderSpace, .liteEDDTemplate .pageFooterSpace {
    height: 120px; }
  .liteEDDTemplate .pageFooter {
    position: fixed;
    z-index: 1;
    bottom: 0;
    width: 100%;
    background: #ffffff; }
    .liteEDDTemplate .pageFooter .dtrmFooter {
      width: 210mm; }
  .liteEDDTemplate .pageHeader {
    position: fixed;
    z-index: 1;
    top: 0mm;
    background: #ffffff;
    width: 100%; }
    .liteEDDTemplate .pageHeader .dtrmHeader {
      width: 210mm; }
  .liteEDDTemplate .page {
    width: 210mm;
    padding: 0 19mm;
    page-break-after: always; }
    .liteEDDTemplate .page.pageOne {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center; }
      .liteEDDTemplate .page.pageOne .buffer {
        -webkit-flex-basis: 100%;
                flex-basis: 100%; }
      .liteEDDTemplate .page.pageOne .placeholder {
        height: 40mm; }
      .liteEDDTemplate .page.pageOne .title {
        font: 72px 'NeuzeitGro';
        color: #2E456C; }
      .liteEDDTemplate .page.pageOne .body {
        font: 12px 'NeuzeitGro';
        color: #244061;
        line-height: 2; }
        .liteEDDTemplate .page.pageOne .body .imageWrapper {
          position: relative;
          left: -48px;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center; }
          .liteEDDTemplate .page.pageOne .body .imageWrapper .dtrmEntityLogo {
            width: 48px;
            height: 48px; }
    .liteEDDTemplate .page.pageTwo .contentPageTitle {
      font: 20px 'NeuzeitGro';
      color: #E36C0A; }
    .liteEDDTemplate .page.pageTwo .contentPageWrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end; }
    .liteEDDTemplate .page.pageTwo .contentTable, .liteEDDTemplate .page.pageTwo .disclaimer {
      width: 120mm; }
    .liteEDDTemplate .page.pageTwo .contentTable {
      font: 12px 'NeuzeitGro';
      padding-bottom: 48px; }
      .liteEDDTemplate .page.pageTwo .contentTable .row-0, .liteEDDTemplate .page.pageTwo .contentTable .row-2, .liteEDDTemplate .page.pageTwo .contentTable .row-3 {
        color: #E36C0A;
        line-height: 36px; }
      .liteEDDTemplate .page.pageTwo .contentTable .row-1, .liteEDDTemplate .page.pageTwo .contentTable .row-4, .liteEDDTemplate .page.pageTwo .contentTable .row-5 {
        color: #1F497D;
        line-height: 36px; }
      .liteEDDTemplate .page.pageTwo .contentTable .row-1, .liteEDDTemplate .page.pageTwo .contentTable .row-4, .liteEDDTemplate .page.pageTwo .contentTable .row-5 {
        padding-left: 16px; }
    .liteEDDTemplate .page.pageTwo .disclaimer {
      font: 8px 'NeuzeitGro';
      line-height: 12px; }
      .liteEDDTemplate .page.pageTwo .disclaimer .title {
        color: #1F497D; }
      .liteEDDTemplate .page.pageTwo .disclaimer .body {
        text-align: justify; }
    .liteEDDTemplate .page.pageThree .basicInfo {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      padding-bottom: 4mm; }
      .liteEDDTemplate .page.pageThree .basicInfo .title {
        font: 20px 'NeuzeitGro';
        color: #E36C0A;
        line-height: 2;
        width: 210mm; }
      .liteEDDTemplate .page.pageThree .basicInfo .subtitle {
        font: 14px 'NeuzeitGro';
        color: #1F497D;
        line-height: 2;
        width: 210mm; }
      .liteEDDTemplate .page.pageThree .basicInfo .body {
        font: 11px 'NeuzeitGro';
        line-height: 2;
        text-align: justify;
        width: 210mm; }
        .liteEDDTemplate .page.pageThree .basicInfo .body > p {
          line-height: 2; }
    .liteEDDTemplate .page.pageThree .adverseMediaAnalysis {
      display: block; }
      .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .prelude {
        font: 11px 'NeuzeitGro';
        line-height: 2; }
      .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .title {
        font: 20px 'NeuzeitGro';
        color: #E36C0A;
        width: 210mm;
        line-height: 2; }
      .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .table {
        display: table;
        border-collapse: collapse; }
      .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .tableBody {
        display: table-row-group; }
      .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .tableRow {
        display: table-row; }
      .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .tableCell {
        border-color: #95B3D7;
        border-style: solid;
        display: table-cell;
        vertical-align: middle; }
      .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .even {
        background: #DBE5F1;
        -webkit-print-color-adjust: exact; }
      .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .summary {
        font: 11px 'NeuzeitGro';
        line-height: 2; }
        .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .summary .left {
          width: 122mm;
          padding-left: 8px;
          border-width: 0 0.5px 0.5px 0; }
        .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .summary .right {
          width: 50mm;
          text-align: center;
          border-width: 0 0 0.5px 0; }
      .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .details {
        margin-top: 10mm;
        font: 11px 'NeuzeitGro';
        line-height: 2; }
        .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .details .header {
          font-weight: bold; }
          .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .details .header .cellOne {
            border-width: 0 0px 0.5px 0;
            width: 30mm;
            padding-left: 8px; }
          .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .details .header .cellTwo {
            border-width: 0 0px 0.5px 0;
            width: 94mm;
            padding-left: 8px; }
          .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .details .header .cellThree {
            border-width: 0 0px 0.5px 0;
            width: 32mm;
            padding-left: 8px; }
          .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .details .header .cellFour {
            border-width: 0 0px 0.5px 0;
            width: 16mm;
            padding-left: 8px; }
        .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .details .tableBody .cellOne {
          border-width: 0 0.5px 0.5px 0;
          width: 30mm;
          padding-left: 8px;
          text-transform: capitalize; }
        .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .details .tableBody .cellTwo {
          border-width: 0 0.5px 0.5px 0;
          width: 94mm;
          padding-left: 8px; }
          .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .details .tableBody .cellTwo .summarySentence {
            line-height: 1.5; }
        .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .details .tableBody .cellThree {
          border-width: 0 0.5px 0.5px 0;
          width: 32mm;
          padding-left: 8px; }
        .liteEDDTemplate .page.pageThree .adverseMediaAnalysis .details .tableBody .cellFour {
          border-width: 0 0 0.5px 0;
          width: 16mm;
          padding-left: 8px; }
    .liteEDDTemplate .page.pageFour .blacklist {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
      .liteEDDTemplate .page.pageFour .blacklist .title {
        font: 20px 'NeuzeitGro';
        color: #E36C0A;
        line-height: 2;
        width: 210mm; }
      .liteEDDTemplate .page.pageFour .blacklist .content {
        width: 172mm; }
        .liteEDDTemplate .page.pageFour .blacklist .content .subtitle {
          font: 14px 'NeuzeitGro';
          color: #1F497D;
          line-height: 2; }
        .liteEDDTemplate .page.pageFour .blacklist .content .body {
          font: 11px 'NeuzeitGro';
          line-height: 2;
          text-align: justify; }
          .liteEDDTemplate .page.pageFour .blacklist .content .body p {
            line-height: 2; }
  .liteEDDTemplate .fineprint {
    font: italic 11px 'NeuzeitGro';
    line-height: 2; }

@page {
  margin: 0; }

@media print {
  .liteEDDTemplate {
    margin: 0; }
    .liteEDDTemplate thead {
      display: table-header-group; }
    .liteEDDTemplate tfoot {
      display: table-footer-group; }
    .liteEDDTemplate button {
      display: none; } }

